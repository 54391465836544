import { savePDPData } from '@/redux/actions';
import { storeProductClickedDetails } from '@/utils/storeProductClickedDetails';
import { canShowPDPSystem } from '@/utils/canShowPDPSystem';
import {
  CLICKED_ITEM_X_OFFSET,
  CLICKED_ITEM_Y_OFFSET,
  PROD_NAME_REPLACE_REGEX,
} from '@/utils/constants';
import { getRoute, PATH } from '@/utils/routes';
import router from 'next/router';
import { useSelector, useDispatch } from 'react-redux';

export function useItemClick() {
  const dispatch = useDispatch();

  const { storeData } = useSelector((state) => ({
    storeData: state.storeReducer.store,
  }));

  /**
   * to go to product description page, special characters are not allowed.
   * using regex to remove all special characters with '-'
   * @param {*} item
   * @param {*} e
   */

  const onItemClick = (item, e = null) => {
    storeProductClickedDetails();
    e && e?.stopPropagation();
    sessionStorage.setItem(CLICKED_ITEM_X_OFFSET, scrollX);
    sessionStorage.setItem(CLICKED_ITEM_Y_OFFSET, scrollY);

    /** if color is there push with color in query params */
    if (item?.color) {
      router.push(
        getRoute(
          `${PATH.productDescription(
            item.id,
            item.name.replace(PROD_NAME_REPLACE_REGEX, '-')
          )}?color=${item?.color}`,
          storeData?.store_info?.domain
        )
      );
    } else {
      canShowPDPSystem(storeData?.theme?.theme_class)
        ? router.push(
            getRoute(
              PATH.productDescription(
                item.id,
                item.name.replace(PROD_NAME_REPLACE_REGEX, '-')
              ),
              storeData?.store_info?.domain
            )
          )
        : dispatch(savePDPData({ visible: true, product: item }));
    }
  };

  const getLink = (item) => {
    const productBaseLink = PATH.productDescription(
      item.id,
      item.name.replace(PROD_NAME_REPLACE_REGEX, '-')
    );
    /** if color is there push with color in query params */
    let link = '';
    if (item?.color) {
      link = getRoute(
        `${productBaseLink}?color=${item?.color}`,
        storeData?.store_info?.domain
      );
    } else {
      if (canShowPDPSystem(storeData?.theme?.theme_class)) {
        link = getRoute(productBaseLink, storeData?.store_info?.domain);
      }
    }
    return link || '';
  };

  return [onItemClick, getLink];
}
